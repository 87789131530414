import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { theme } from '@styles';
import PlayIcon from '@assets/images/play-icon.svg';
import ExternalLinkIcon from '@assets/icons/ExternalLinkIcon';
import Button from '@components/Button';
import CustomText from '@components/CustomText';
import { GRANNY_SMITH } from '@constants';
import { IPlan } from '@appTypes';
import { capitalizeFirstLetter } from '@utils';

const { colors, breakpoints } = theme;

type Props = {
  plan: IPlan;
  index: any;
  shouldPause: boolean;
  onVideoPlay: any;
  fullWidth?: boolean;
  withDescription?: boolean;
};

const PlanCard: React.FC<Props> = ({ plan, index, shouldPause, onVideoPlay, fullWidth }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showPlayIcon, setShowPlayIcon] = useState(true);

  useEffect(() => {
    if (shouldPause) {
      if (!videoRef?.current?.paused) {
        videoRef?.current?.pause();
        setShowPlayIcon(true);
      }
    }
  }, [shouldPause]);

  const handleVideoClick = () => {
    if (videoRef?.current?.paused) {
      videoRef?.current.play();
      setShowPlayIcon(false);
    } else {
      setShowPlayIcon(true);
      videoRef?.current?.pause();
    }
  };

  return (
    <Container key={index} fullWidth={fullWidth}>
      <PlanCardHeader fullWidth={fullWidth}>
        {plan.preview && (
          <VideoWrapper
            data-index={index}
            onClick={(e) => {
              handleVideoClick();
              onVideoPlay(e);
            }}
          >
            {showPlayIcon && (
              <PlanPlayImage
                className='play-icon'
                style={{
                  marginRight: '10px',
                }}
                src={PlayIcon}
                alt={'Play icon'}
              />
            )}
            <PlanVideo
              ref={videoRef}
              poster={plan.preview.poster}
              src={plan.preview.url}
              playsInline
              preload={'none'}
              loop
            />
          </VideoWrapper>
        )}
      </PlanCardHeader>
      <PlanBody>
        <PlanNameContainer>
          <PlanName>{plan.name}</PlanName>
        </PlanNameContainer>
        <CustomText fontSize={16} lineHeight={'23px'} fontWeight={400} mt={'8px'} mb={'6px'}>
          {plan.goals.map((goal) => goal.name).join(', ')} |{' '}
          {plan.difficulty ? capitalizeFirstLetter(plan.difficulty) : ''}
        </CustomText>
        <CustomText fontSize={16} lineHeight={'23px'} color={GRANNY_SMITH} fontWeight={400}>
          {plan.description}
        </CustomText>
      </PlanBody>
      <PlanFooter>
        <ButtonWrapper fullWidth={fullWidth}>
          <a href={`/plan?planId=${plan.id}`} target='_blank' rel={'noreferrer'}>
            <Button
              fontSize={14}
              icon={<ExternalLinkIcon width={24} height={24} color={colors.outerSpace} />}
              label='Open Program'
              type={'tertiary'}
            />
          </a>
        </ButtonWrapper>
      </PlanFooter>
    </Container>
  );
};

export default PlanCard;

const ButtonWrapper = styled.div<{ fullWidth?: boolean }>`
  height: 50px;
  width: ${({ fullWidth }) => (fullWidth ? '183px' : '100%')};
  margin: 0px 24px 24px 24px;
  a {
    text-decoration: none;
  }
`;

const PlanFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const PlanNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlanName = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
  color: ${colors.outerSpace};
  width: 80%;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    font-size: 20px;
  }
`;

const PlanVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const PlanCardHeader = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 230px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    ${({ fullWidth }) =>
      fullWidth
        ? `height: 618px;
  }`
        : `height: 230px`};
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    .play-icon {
      opacity: 0.8;
    }
  }
`;

const PlanPlayImage = styled.img`
  position: absolute;
  z-index: 3;
`;

const PlanBody = styled.div`
  padding: 24px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  flex: 1;
`;

const Container = styled.div<{ fullWidth?: boolean }>`
  margin: 0 auto;
  align-items: center;
  border: 1px solid ${colors.mystic};
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  max-width: 327px;
`;
